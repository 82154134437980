<template>
  <div>
    <section class="site-testimoial-view">
      <div class="container">
        <!-- <h1 class="page-heading-2">
          COUSTOMER FEEDBACKS
        </h1> -->
        <h1 class="heading-text-2">
          What People Say
        </h1>
        <vue-slick-carousel
          v-if="reviews && reviews.length > 0"
          :dots="true"
          v-bind="settings"
        >
          <div
            v-for="(item) in reviews"
            :key="'A' + item.id"
            class="t-r-slide"
          >
            <div class="site-t-card">
              <b-avatar
                v-if="item.reviewer && item.reviewer.avatar === null"
                size="70px"
                variant="success"
              />
              <b-avatar
                v-else
                size="70px"
                :src="getImage(item.reviewer.avatar)"
              />
              <div class="line-view" />
              <div class="t-content">
                <h2 class="Donney Jon">
                  {{ item.reviewer.full_name }}
                </h2>
                <p>{{ item.review }}</p>
                <div class="star-rating-view">
                  <feather-icon
                    v-for="i in item.rating"
                    :key="i"
                    class="rating-star"
                    icon="StarIcon"
                  />
                  <feather-icon
                    v-for="i in 5-item.rating"
                    :key="'B' + i"
                    class=""
                    icon="StarIcon"
                  />
                </div>
              </div>
            </div>
          </div>
        </vue-slick-carousel>
      </div>
    </section>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    reviews: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      settings: {
        // centerMode: true,
        // centerPadding: '20px',
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        autoplay: true,
        arrows: true,
        dots: true,
      },
    }
  },
  methods: {
    getImage(image) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
  },
}
</script>

<style>

</style>
