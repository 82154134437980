<template>
  <div>
    <!-- service view shows -->

    <section
      id="hwa-service"
      class="service-view-box"
    >
      <div class="container">
        <h3 class="page-heading-2 text-center">
          Mortgage Types
        </h3>
        <h1 class="contact-title text-center">
          We Offer Diffrent Mortgages <br>
          For Your Convenience
        </h1>

        <div class="row mt-3">
          <!-- service card start -->
          <div
            v-for="(item, index) in services"
            :key="index"
            class="col-md-6"
          >
            <div
              class="site-service-card"
              @click="showContent(item.slug)"
            >
              <h2>{{ item.title }} </h2>
              <p> {{ item.body | setTruncate(170,"...") }}</p>

              <span>Learn More</span>
            </div>
          </div>
          <!-- service card end -->
        </div>
      </div>
    </section>
    <!-- service view ends -->
  </div>
</template>

<script>

export default {
  data() {
    return {
      services: [],
    }
  },
  mounted() {
    this.getServices()
  },
  methods: {
    showContent(title) { this.$router.push(`/mortgage/${title}`).catch(() => { }) },
    getServices() {
      this.$http.get('/api/services')
        .then(res => {
          this.services = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve services')
        })
    },
  },
}
</script>

<style>

</style>
