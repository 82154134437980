<template>
  <div>
    <navbar />
    <!-- <bread-creamb title="Mortgage Type" /> -->
    <service />
    <!-- <testimonial />
    <blog /> -->
    <subscribe />
    <app-footer />
  </div>
</template>

<script>
import AppFooter from './site-content/AppFooter.vue'
import Blog from './site-content/Blog.vue'
import Navbar from './site-content/Navbar.vue'
import Service from './site-content/Service.vue'
import Subscribe from './site-content/Subscribe.vue'
import Testimonial from './site-content/Testimonial.vue'

export default {
  name: 'Partner',
  components: {
    Service,
    Testimonial,
    Blog,
    Subscribe,
    AppFooter,
    Navbar,
  },
  data() {
    return {

    }
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
